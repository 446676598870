.flex-grid {
  display: flex;
  flex-direction: column;
}
.col {
  flex: 1;
}

.dashboardHeading {
  margin: 0pt;
  padding: 5pt;
  background-color: cornsilk;
  width: 100%;
}
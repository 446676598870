.logo {
    margin  : 0;
    float   : left;
    height  : 62px;
    position: 'fixed';
    left    : 0;
    width   : '200px'
}

.logo:hover {
    cursor: pointer;
}

.site-layout-content {
    /* min-height: 700px; */
    margin             : 65px 15px 0 15px;
    /* background-color: rgba(221, 221, 221, 0.781); */

}

.site-layout {
    background: #F0F2F5;
}

/* .header {
    height: 75
} */

.main-content-area {
    background-position   : center;
    background-repeat     : no-repeat;
    background-size       : cover;
    /* margin             : '0 16px'; */

}

.footer {
    padding                  : 0;
    /* position              : fixed; */
    /* bottom                : 0; */
    text-align               : center;
    background-color         : #001529;
    color                    : white;
    /* height                : 6vh; */
    width                    : 100%;
}



/* --------------------------------- */
/* ------------Sider----------- */
/* --------------------------------- */

.sider .ant-layout-sider {
    overflow  : auto;
    position  : static;
    left      : 0;
    min-height: 100vh;
    background: linear-gradient(to right, #004180, #001c34);
}

.sider-menu {
    background       : linear-gradient(to right, #004180, #001c34);
    height           : "100%";
    border-right     : 0;
    border-left-color: 0;
    margin           : '64px 0';
    color            : rgb(233, 233, 233);
}


.sider-btn {
    /* background   : linear-gradient(to right, #004180, #0089ff00); */
    background   : unset;
    margin-bottom: 0px;
    height       : 100%;
}

.ant-layout-sider {
    background   : unset;
    /* background: linear-gradient(to right, #004180, #0089ff00); */

}

.sider .ant-menu-inline .ant-menu-item {
    font-family  : sans-serif;
    margin       : 0px;
    padding      : 5px 0;
    height       : unset;
    color        : #e2e2e2;
    background   : unset;
    border-bottom: solid 1px;
    border-image :
        linear-gradient(to right,
            rgb(0 40 109),
            rgb(245 2 2 / 0%)) 100% 10%;

}

.sider .ant-menu-item:hover {
    background : linear-gradient(to right, #024d98, #0089ff00);
    font-weight: 450;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background  : linear-gradient(to right, #0053a5, #0089ff00);
    border-right: 0;
    color       : #ffffff;
}




/* --------------------------------- */
/* --------------Header------------- */
/* --------------------------------- */

.header-menu {
    color    : #001b34;
    font-size: large;
}

.site-layout .header {
    background-color: #FFF;
    border          : 1px solid rgba(167, 167, 167, 0.541);
}


.header .header-logo {
    height: 60px;

}



.acc-dropdown {
    color: #001b34;
}

.acc-dropdown .ant-btn-link {
    color: #001b34;
}

.acc-dropdown .ant-btn-link:hover {
    color: #001b34;
}

.avatar-btn {
    color : #001b34;
    height: 64px;
}

.avatar-btn span:not(.ant-avatar, .ant-avatar-string) {
    padding: 5px;
}

.ant-btn {
    margin-right : 8px;
    margin-bottom: 8px;
}

.ant-row-rtl .ant-btn {
    margin-right : 0;
    margin-bottom: 8px;
    margin-left  : 8px;
}





/* ---------------------------------- */
/* ------------Form Fields----------- */
/* ---------------------------------- */

.ant-btn,
.ant-input {
    border-radius: 5px;
    margin       : 0px;
}

.ant-btn-primary {
    border           : 0;
    color            : #fff;
    background-color : #6cb33f;
    background-image : -webkit-linear-gradient(top, #79c04c, #61a038);
    background-image : linear-gradient(180deg, #79c04c 0, #61a038);
    background-repeat: repeat-x;
}

.ant-btn-primary:hover {
    background-image : -webkit-linear-gradient(top, #6cb33f, #6cb33f);
    background-image : linear-gradient(180deg, #6cb33f 0, #6cb33f);
    background-repeat: repeat-x;
}

/* --------------------------------- */
/* ------------Scroll Bar----------- */
/* --------------------------------- */

/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
#components-grid-demo-flex-align [class~="ant-row"] {
  background: rgba(128, 128, 128, 0.08);
}

.img-auth {
  display   : block;
  width     : 75vw;
  height    : 100vh;
  object-fit: cover;
}


.register-page,
.login-page {
  background     : url("./BG2.png") no-repeat;
  background-size: cover;
  width          : 100%;
  min-height     : 100vh;

  /* filter        : blur(2px); */
}


.login-bttn {
  width: -webkit-fill-available;
}

/* vertical line */
.vl {
  position : absolute;
  left     : 50%;
  transform: translate(-50%);
  border   : 2px solid #ddd;
  height   : 175px;
}


/* text inside the vertical line */
.vl-innertext {
  position        : absolute;
  top             : 50%;
  transform       : translate(-50%, -50%);
  background-color: #f1f1f1;
  border          : 1px solid #ccc;
  border-radius   : 50%;
  padding         : 8px 10px;
}

.login-form {
  /* max-width: 300px; */
  padding-right: 20px;
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width            : 100%;
  border           : 0;
  color            : #fff;
  background-color : #6cb33f;
  background-image : -webkit-linear-gradient(top, #79c04c, #61a038);
  background-image : linear-gradient(180deg, #79c04c 0, #61a038);
  background-repeat: repeat-x;

}

.login-form-button:hover {
  background-image : -webkit-linear-gradient(top, #6cb33f, #6cb33f);
  background-image : linear-gradient(180deg, #6cb33f 0, #6cb33f);
  background-repeat: repeat-x;
}

.divider-h,
.ant-divider-with-text,
.ant-divider-with-text-center {
  color    : #7f7f7f;
  font-size: small;
}

.signin-card {
  border          : 1px solid #f0f0f045;
  background-color: #ffffffa8;
  border-radius   : 10px;
}